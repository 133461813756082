


















import Socket from '@/ws'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class ReconnectionBar extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) readonly hasPinned!: boolean

  show = false
  timeoutId = 0

  @Getter isSocketOpen!: () => boolean
  @Getter isSocketClosedByClient!: () => boolean

  get classes (): Record<string, boolean> {
    return {
      off: Boolean(this.timeoutId),
      'reconnection-bar--has-pinned': this.hasPinned,
    }
  }

  @Watch('isSocketOpen', { immediate: true })
  onSocketOpen (current: boolean, previous: boolean) {
    this.show = (!current && previous !== undefined) && !this.isSocketClosedByClient
  }

  beforeDestroy (): void{
    this.clear()
  }

  reconnect (): void{
    if (this.timeoutId) return

    Socket.startTryingReconnect()

    this.timeoutId = setTimeout(this.clear, 3000)
  }

  clear (): void {
    clearTimeout(this.timeoutId)
    this.timeoutId = 0
  }
}
